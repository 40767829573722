/* dashboard.css */

.dashboard-container {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

@media (min-width: 993px) {
  .dashboard-container-1 {
    display: flex !important;
  }
  .tChargers-section-container {
    padding: 0rem 1rem 1rem 0rem !important;
    border-right: solid 0.1rem #344767;
    border-bottom: solid 0.1rem #344767;
  }
  .pSummary-section-container {
    padding: 0rem 0rem 1rem 1rem !important;
    border-bottom: solid 0.1rem #344767;
  }
  .total-chargers-container {
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
  }

  .total-chargers-box-container {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    width: 100% !important;
  }

  .total-chargers-box {
    width: auto !important;
  }

  .dashboard-header-text {
    text-align: center !important;
    width: 100% !important;
  }
}
