@media (max-width: 768px) {
  .filter-container {
    flex-direction: column; /* Stack the children vertically */
    justify-content: flex-start; /* Align children to the start of the container */
    align-items: center; /* Center the children horizontally */
    width: 100%; /* Ensure the container takes full width */
    margin: 0 auto;
  }

  .filter-container > * {
    margin-bottom: 8px; /* Space between stacked items */
    width: 90%; /* Ensure the children are 90% of the container width */
  }

  .allchargerpadding {
    padding-left: 8px;
  }

  .date-picker {
    margin-bottom: 12px !important;
  }

  .alert-section {
    width: 90%;
    margin: 0 auto;
  }
}
