/* styles.css */

.table-container {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.author-link {
  text-decoration: none;
  color: inherit;
}

th,
td {
  padding: 8px;
  text-align: left;
}

@media (max-width: 768px) {
  th,
  td {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  th {
    display: none; /* Hide headers on screens smaller than 768px */
  }

  td {
    box-sizing: border-box;
    text-align: center; /* Center align text in table data */
  }

  .responsive-arrow {
    font-size: 2rem !important;
    color: #343434 !important;
  }
}
