/* styles.css */
#chart-container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

#chart {
  min-width: 1200px; /* Adjust this value based on your heatmap's preferred minimum width */
}

@media (max-width: 768px) {
  #chart-container {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }
}
