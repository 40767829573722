.navbar-links {
  display: flex;
  align-items: center;
}

.nav-links {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .navbar-links {
    flex-direction: column;
    align-items: flex-end;
  }

  .nav-links {
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }

  .nav-links a {
    margin-right: 0;
    margin-bottom: 8px;
  }

  .summary-link {
    display: flex !important;
  }
}
